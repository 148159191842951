import "§/assets/_template.scss";

import {etExport as et} from "./000_base"

import {
    updateViewportDimensions,
    etUpdateTeaserimageVideo,
    etInitNavigation,
    etInitAutomaticAnchorMenu,
    //etAppScrollSmoothTo,
    initVideoPlayPauseButton
} from './template';

import {
    switchProducts,
    paymentSwitch,
    compareTableAccordion
} from './preisuebersicht';

import { customFunction } from './400_custom';
import { etCookieControl} from "./420_cookiecontrol";
import { lightgalleryImageSettings, lightgalleryVideoSettings } from "./430_lightgallery_settings";
import { Navigation} from "./navigation";
let ga;

customFunction(et);

window.addEventListener('load', function () {
    new Navigation(document.getElementsByClassName('menu__wrapper--l1')[0]);

    initVideoPlayPauseButton();

    // Get variable abfragen und wcag js hinzufügen
    const queryString = window.location.search;
    if (queryString != "") {
        const urlParams = new URLSearchParams(queryString);
        const selector = urlParams.get('wcag_element_selector');
        if (selector != "") {
            const wcagScript = document.createElement('script');
            wcagScript.src = '/module/wcag_page/frontend/wcag_page_frontend.js';
            wcagScript.type = 'module';
            document.getElementsByTagName('head')[0].appendChild(wcagScript);
        }
    }
});

window.addEventListener('DOMContentLoaded', function (event) {
    et.app.callPersonalJson();
    updateViewportDimensions(event);

    paymentSwitch();
    switchProducts();
    compareTableAccordion();

    et.api.cookieControll = new etCookieControl();
    et.api.cookieControll.init();

    document.documentElement.addEventListener('click', function () {
        var r = [
            '.menu__item--desktop_touched',
            '.element_menue_aam_mobile--open'
        ];
        r.forEach(function (selector) {
            var className = selector.substring(1);
            document.querySelectorAll(selector).forEach(function (element) {
                element.classList.remove(className);
            });
        });
    });

    etInitNavigation();

    document.querySelectorAll('.element_menue_aam').forEach(function (aamElement) {
        etInitAutomaticAnchorMenu(aamElement);
    });

    // document.querySelectorAll<HTMLLinkElement | any>('a[href^="#"]').forEach(function (linkElement) {
    //     if (!linkElement.dataset.scollSmooth) {
    //         linkElement.dataset.scrollSmooth = 'etApp';
    //         linkElement.addEventListener('click', function (event) {
    //             event.preventDefault();
    //             etAppScrollSmoothTo(document.querySelector(linkElement.hash));
    //             location.href = event.target.href;
    //         });
    //     }
    // });

    document.querySelectorAll('a[class*="--lightgallery_image"]').forEach(function (domElement: HTMLAnchorElement) {
        const imageElements = domElement.querySelectorAll<HTMLImageElement>('img[data-src-lightgallery]');
        imageElements.forEach(function (imageElement) {
            imageElement.addEventListener('lgAfterClose', function (event) {
                domElement.focus();
            });
            const dynamicGallery = lightgalleryImageSettings(imageElement);
            domElement.addEventListener('click', function (event) {
                event.preventDefault();
                dynamicGallery.openGallery();
            });
        });
    });

    document.querySelectorAll<HTMLLinkElement>('a[href*="youtu.be"],a[href*="youtube.com"],a.element_textblock_youtube_video__link').forEach(function (linkElement) {
        const dynamicGallery = lightgalleryVideoSettings(linkElement);
        linkElement.addEventListener('click', function (event) {
            event.preventDefault();
            if (typeof ga === 'function') {
                var label = linkElement.getAttribute('aria-label') || linkElement.getAttribute('title') || linkElement.getAttribute('href');
                ga('send', {
                    hitType: 'event',
                    eventCategory: 'Videos',
                    eventAction: 'play',
                    eventLabel: label
                });
            }
            dynamicGallery.openGallery();
        });
    });
});

document.addEventListener('DOMContentLoaded', function (event) {
    document.querySelectorAll('a[href^="#frm"][href*=";;"]').forEach(function (anchorElement:HTMLAnchorElement) {
        anchorElement.addEventListener('click', function (event) {
            event.preventDefault();
            jumpToFormAndSelectAnOption(anchorElement.href);
        })
    });
});

window.addEventListener('load', function (event) {
    etUpdateTeaserimageVideo();

    if (window.location.hash.match('#frm[0-9]+;;')) {
        jumpToFormAndSelectAnOption(window.location.hash);
    }
});

function jumpToFormAndSelectAnOption(targetString) {
    var h = targetString.match(/#(frm.*?);;(.+)/);
    if (h && h.length === 3) {
        var formElement = document.getElementById(h[1]);
        if (!formElement || formElement.tagName !== 'FORM') {
            formElement = document.querySelector('form[name="' + h[1] + '"]')
        }
        if (formElement) {
            formElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            var fieldVals = String(h[2]).split(';;');
            if (fieldVals) {
                fieldVals.forEach(function (fieldVal) {
                    var i = fieldVal.indexOf('.');
                    var fk = fieldVal.substr(0, i);
                    var fv = fieldVal.substr(i + 1);
                    var fieldElement = formElement.querySelector<HTMLInputElement>('#' + fk);
                    if (fieldElement) {
                        fieldElement.value = fv;
                    }
                })
            }
        }
    }
}

window.addEventListener('resize', function (event) {
    updateViewportDimensions(event);
    etUpdateTeaserimageVideo();
});

document.addEventListener('et.after.callPersonalJson', function (event: any) {
    //console.debug('hit et.after.callPersonalJson', {response: event.detail.response});
    if (event.detail.hasOwnProperty('response')
        && typeof event.detail.response /* not null/empty */
        && typeof event.detail.response === 'object'
    ) {
        // if (event.detail.response.hasOwnProperty('data')) {}
        let inputElement = document.querySelector<HTMLInputElement>('.keep-pt input');
        if (inputElement) {
            inputElement.value = event.detail.response.form.token;
        }
    }
});
