var aamIdi = 0;

export function updateViewportDimensions(event) {
    et.data.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    et.data.viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

export function etUpdateTeaserimageVideo() {
    if (et.data.viewportWidth >= 1025) {
        if (!et.data.hasOwnProperty('etUpdateTeaserimageVideo')) {
            et.data.etUpdateTeaserimageVideo = true;
            et.data.teaserimageVideos = document.querySelectorAll<HTMLVideoElement>('.displayblock_teaserimage video').forEach(function (videoElement) {
                videoElement.play();
            });
        }
    }
}

export function etInitNavigation() {
    var menuIcon = document.getElementById('navigation_icon');
    var menu = document.getElementById('navigation');
    var cnOpen = 'menu--open';
    var cnNoScroll = 'menu--noscroll';
    var cnMenuWrapper = 'menu__wrapper';
    var i, l, link, w, p;

    if (menuIcon && menu) {

        /* iOS Workaround */
        menu.addEventListener('click', function () {
        });

        /* close menu */
        document.documentElement.addEventListener('click', function () {
            Array(cnOpen, cnNoScroll).forEach(function (className) {
                var elements = document.querySelectorAll('.' + className);
                for (var i = 0, l = elements.length; i < l; i++) {
                    elements[i].classList.remove(className);
                }
            });
        });

        /* open menu */
        menuIcon.addEventListener('click', function (event) {
            event.stopPropagation();
            document.documentElement.classList.add(cnNoScroll);
            document.documentElement.classList.add(cnOpen);
        });

        /* clickable menu items */
        var menuLinks = menu.querySelectorAll('.menu__link');
        for (i = 0, l = menuLinks.length; i < l; i++) {
            menuLinks[i].addEventListener('click', function (event) {
                if (document.documentElement.classList.contains(cnOpen)) {
                    event.stopPropagation();
                    link = event.target;
                    if (link.classList.contains('menu__link--back')) {
                        w = link.closest('.' + cnOpen);
                        if (w) {
                            w.classList.remove(cnOpen);
                            p = w.parentElement.parentElement;
                            if (p.classList.contains(cnMenuWrapper)) {
                                p.classList.remove(cnNoScroll);
                            }
                        }
                    } else if (link.parentElement.classList.contains('menu__item--parent')) {
                        event.preventDefault();
                        w = link.parentElement.querySelector('.' + cnMenuWrapper);
                        if (w) {
                            w.classList.add(cnOpen);
                            p = w.parentElement.parentElement;
                            if (p.classList.contains(cnMenuWrapper)) {
                                p.classList.add(cnNoScroll);
                            }
                        }
                    }
                } else if ('ontouchstart' in window) {
                    event.stopPropagation();
                    var className = 'menu__item--desktop_touched';
                    link = event.target;
                    p = link.closest('.menu__item--l1');

                    if (!p.classList.contains(className)) {
                        event.preventDefault();
                        document.querySelectorAll('.' + className).forEach(function (element) {
                            element.classList.remove(className);
                        });
                        p.classList.add(className);
                    }
                }
            });
        }
    }
}


export function etInitAutomaticAnchorMenu(aamElement) {
    if (aamElement instanceof HTMLDivElement && !aamElement.dataset.aam) {
        aamElement.dataset.aam = 'ok';

        var isMobile = aamElement.classList.contains('element_menue_aam_mobile');
        var type = (isMobile ? 'mobile' : 'desktop');
        var aamShown = 0;
        if ('sessionStorage' in window) {
            aamShown = parseInt(sessionStorage.getItem('element_menue_aam__shown') || "0");
        }

        if (aamShown < 3 && isMobile) {
            aamElement.classList.add('element_menue_aam_mobile--open');
            if (sessionStorage) {
                sessionStorage.setItem('element_menue_aam__shown', String(aamShown + 1));
            }
        }

        var contentElement = document.getElementById('content');
        if (contentElement) {
            var anchorSet = [];
            var foundAam = false;
            contentElement.querySelectorAll('h1,h2,h3,h4,h5,h6,[class$="__subject"],.element_menue_aam').forEach(function (elementHeadline, index) {
                if (elementHeadline.classList.contains('element_menue_aam_desktop')) {
                    foundAam = true;
                } else {
                    var idElement = elementHeadline.closest('[id]');

                    if (!idElement || idElement === contentElement) {
                        elementHeadline.id = 'a_aam_g' + (++aamIdi);
                        idElement = elementHeadline;
                    }

                    if (idElement) {
                        anchorSet.push({
                            id: idElement.id,
                            text: elementHeadline.innerHTML,
                            // tagName: elementHeadline.tagName,
                            // index: index,
                            preAam: !foundAam
                        });
                    }
                }
            });

            if (anchorSet.length) {
                if (isMobile) {
                    var aamToggleButton = document.createElement('button');
                    aamToggleButton.type = 'button';
                    // TODO: GTE
                    aamToggleButton.title = 'Springe zu';
                    aamToggleButton.classList.add('element_menue_aam_mobile__m_toggle')
                    aamToggleButton.addEventListener('click', function (event) {
                        window.clearTimeout(aamTimeout);
                        event.stopPropagation();
                        aamElement.classList.toggle('element_menue_aam_mobile--open');
                    });
                    aamElement.append(aamToggleButton);
                }

                var aamContainer = document.createElement('ul');
                aamContainer.classList.add('element_menue_aam_' + type + '__c');
                aamElement.append(aamContainer);

                anchorSet.forEach(function (anchorObj) {
                    var listElement = document.createElement('li');
                    var anchorLink = document.createElement('a');
                    anchorLink.classList.add('element_menue_aam_' + type + '__link');
                    anchorLink.href = '#' + anchorObj.id;
                    anchorLink.innerHTML = anchorObj.text;
                    anchorLink.dataset.id = anchorObj.id;
                    // anchorLink.dataset.index = anchorObj.index;
                    // anchorLink.dataset.tagName = anchorObj.tagName;
                    anchorLink.dataset.preAam = anchorObj.preAam;
                    // anchorLink.addEventListener('click', function (event) {
                        // event.stopPropagation();
                    // });
                    listElement.append(anchorLink);
                    aamContainer.append(listElement);
                });

                var aamTimeout = window.setTimeout(function () {
                    aamElement.classList.remove('element_menue_aam_mobile--open');
                }, 1500);
            }
        }
    }
}

export function etAppScrollSmoothTo(target) {
    var offset = 20;
    var layouHeader = document.querySelector<HTMLElement>('.layout__header');
    if (layouHeader) {
        offset += layouHeader.offsetHeight;
    }
    target.scrollIntoView({behavior: 'smooth', block: 'center'});
}

export function initVideoPlayPauseButton() {
    // console.debug('initVideoPlayPauseButton');
    const videoElement = document.querySelector('.displayblock_teaserimage video') as HTMLVideoElement;
    const playButton = document.querySelector('.js_displayblock_teaserimage__image_video_control_play_button') as HTMLButtonElement;
    const pauseButton = document.querySelector('.js_displayblock_teaserimage__image_video_control_pause_button') as HTMLButtonElement;
    const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`);

    if(!videoElement || !playButton || !pauseButton) {
        return;
    }

    if (isReduced.matches) {
        toggleClasses();
        setTimeout(() => {
            if (!videoElement.paused) {
                videoElement.pause();
            }
        }, 0);
    }

    playButton.addEventListener('click', () => {
        toggleClasses();
        pauseButton.focus();
        videoElement.play();
    });

    pauseButton.addEventListener('click', () => {
        toggleClasses();
        playButton.focus();
        videoElement.pause();
    });

    function toggleClasses() {
        playButton.classList.toggle('displayblock_teaserimage__image_video_control_button--playing');
        pauseButton.classList.toggle('displayblock_teaserimage__image_video_control_button--playing');
    }

    // videoElement.addEventListener('ended', () => {
    //     pauseButton.classList.remove('displayblock_teaserimage__image_video_control_button--playing');
    // });
}
