import lightGallery from "lightgallery";
import lgVideo from "lightgallery/plugins/video";

var lgGlobalParameter:object = {
    dynamic: true,
    fullScreen: false,
    zoom: false,
    share: false,
    counter: false,
    download: false,
    enableDrag: false,
    licenseKey: "415B960F-32AF-4601-96E6-60E098F7A0FE"
};

export function lightgalleryImageSettings(imageElement) {
    var lgLocalParameter:object = {
        dynamicEl: [{
            'src': imageElement.getAttribute('data-src-lightgallery'),
            'thumb': '',
            'srcset': imageElement.getAttribute('data-srcset-lightgallery'),
            'sizes': imageElement.getAttribute('data-sizes-lightgallery')
        }]
    };
    const obj = {...lgGlobalParameter, ...lgLocalParameter}

    return lightGallery(imageElement, obj);
}

export function lightgalleryVideoSettings(linkElement) {
    var lgLocalParameter = {
        dynamicEl: [{
            'src': linkElement.href,
            'title': linkElement.getAttribute('data-video-label'),
        }],
        loadYouTubeThumbnail: true,
        youTubeThumbSize: 1,
        plugins: [lgVideo],
        youTubePlayerParams: {
            mute : 0
        }
    };
    const obj = {...lgGlobalParameter, ...lgLocalParameter}

    return lightGallery(linkElement, obj);
}