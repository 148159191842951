function changeProduct(element) {
    const compareTable:any = document.querySelector('#js_element_displayblock_preisuebersicht__compare_table');
    const itemContainer:any = document.querySelector('.js_element_displayblock_preisuebersicht__container');
    var nth = 2;
    var switch_button_product = element.dataset.product;
    const itemContainerChildren = itemContainer.children;
    for (var i = 0; i < itemContainerChildren.length; i++) {
        var item:HTMLElement = itemContainerChildren[i];
        if (switch_button_product != item.dataset.product) {
            item.classList.remove('element_displayblock_preisuebersicht__item--visibility');
            compareTable.querySelectorAll('td:nth-child(' + nth + '), th:nth-child(' + nth + ')').forEach(function(value){
                value.classList.remove('element_displayblock_preisuebersicht__compare_table--visibility');
            });
        }
        else {
            item.classList.add('element_displayblock_preisuebersicht__item--visibility');
            compareTable.querySelectorAll('td:nth-child(' + nth + '), th:nth-child(' + nth + ')').forEach(function(value){
                value.classList.add('element_displayblock_preisuebersicht__compare_table--visibility');
            });
        }
        nth++;
    }
}

function changePrice(paymentType) {
    const itemPrice = document.querySelectorAll<HTMLElement>('.js_element_displayblock_preisuebersicht__item_price_value');
    itemPrice.forEach(function (item){
        var newPrice = '';
        if (paymentType == 'month') {
            newPrice = item.dataset.price;
        }
        else if (paymentType == 'year') {
            newPrice = item.dataset.reduced_price;
        }
       item.innerHTML = newPrice;
    });
}

function setClassAndAttribute(currentElement, newElement, activeClass) {
    currentElement.classList.remove(activeClass);
    currentElement.setAttribute('aria-selected', 'false')
    currentElement.setAttribute('tabindex', '-1')

    newElement.classList.add(activeClass);
    newElement.setAttribute('aria-selected', 'true')
    newElement.removeAttribute('tabindex')
}

function pricingChangeAttributes() {
    const itemContainer = document.querySelector('.js_element_displayblock_preisuebersicht__container');
    const srOnlyItems = document.querySelectorAll('.element_displayblock_preisuebersicht__item_sr_only');
    let ariaLabel = itemContainer.getAttribute('aria-labelledby');

    srOnlyItems.forEach(function (element) {
        element.setAttribute('aria-hidden', 'false');
        if (element.getAttribute('aria-labelledby') == ariaLabel) {
            element.setAttribute('aria-hidden', 'true');
        }
    })
    switch (ariaLabel) {
        case 'pricing_month_year_toggle_month':
            itemContainer.setAttribute('aria-labelledby', 'pricing_month_year_toggle_year');
            itemContainer.id = 'pricing_month_year_toggle_year_panel';
            break;
        case 'pricing_month_year_toggle_year':
            itemContainer.setAttribute('aria-labelledby', 'pricing_month_year_toggle_month');
            itemContainer.id = 'pricing_month_year_toggle_month_panel';
            break;
    }
}

function switchTab(currentTab, activeClass, tabGroup, direction) {
    var newTab:HTMLElement;
    var element:HTMLElement;
    var i:number;

    if (direction == 'left') {
        if (currentTab.previousSibling != null && currentTab.previousSibling.hasAttribute('role')) {
            newTab = currentTab.previousSibling;
        }
        else {
            for (i = currentTab.parentNode.children.length-1; i > 0; i--) {
                element = currentTab.parentNode.children[i];
                if (element.hasAttribute('role')) {
                    newTab = element;
                    break;
                }
            }
        }
    }
    else if (direction == 'right') {
        if (currentTab.nextSibling != null && currentTab.nextSibling.hasAttribute('role')) {
            newTab = currentTab.nextSibling;
        }
        else {
            for (i = 0; i < currentTab.parentNode.children.length; i++) {
                element = currentTab.parentNode.children[i];
                if (element.hasAttribute('role')) {
                    newTab = element;
                    break;
                }
            }
        }
    }
    else if (direction == 'first') {
        for (i = 0; i < currentTab.parentNode.children.length; i++) {
            element = currentTab.parentNode.children[i];
            if (element.hasAttribute('role')) {
                newTab = element;
                break;
            }
        }
    }
    else if (direction == 'last') {
        for (i = currentTab.parentNode.children.length-1; i > 0; i--) {
            element = currentTab.parentNode.children[i];
            if (element.hasAttribute('role')) {
                newTab = element;
                break;
            }
        }
    }

    setClassAndAttribute(currentTab, newTab, activeClass);
    newTab.focus();

    if (tabGroup == 0) {
        var buttonOverlay = document.querySelector('.js_element_displayblock_preisuebersicht__payment_switch_button_overlay');
        buttonOverlay.classList.toggle('element_displayblock_preisuebersicht__payment_switch_button_overlay--toggle');

        pricingChangeAttributes();

        var paymentType = newTab.dataset.payment_type;
        changePrice(paymentType);
    }
    else if (tabGroup == 1) {
        changeProduct(newTab);
    }
}

function onKeydown(element, event, activeClass, tabGroup) {
    console.log(event.key);
    switch (event.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
            event.preventDefault();
            switchTab(element, activeClass, tabGroup, 'left');
            break
        case 'ArrowDown':
        case 'ArrowRight':
            event.preventDefault();
            switchTab(element, activeClass, tabGroup, 'right');
            break
        case 'Home':
            event.preventDefault();
            switchTab(element, activeClass, tabGroup, 'first');
            break;
        case 'End':
            event.preventDefault();
            switchTab(element, activeClass, tabGroup, 'last');
            break;
    }
}

export function switchProducts() {
    const itemContainer:any = document.querySelector('.js_element_displayblock_preisuebersicht__container');
    const activeClass = 'element_displayblock_preisuebersicht__product_switch_button--active_product';
    if  (itemContainer){
        const productSwitchButtons = document.querySelectorAll<HTMLElement>('.js_element_displayblock_preisuebersicht__product_switch_button');
        productSwitchButtons.forEach(function (element){
            element.addEventListener('keydown', function(event) { onKeydown(this, event, activeClass, 1) });
            element.addEventListener('click', function (event){
                event.preventDefault();
                var acticeProductButton = document.querySelector('.'+activeClass);
                setClassAndAttribute(acticeProductButton, element, activeClass);
                changeProduct(element);
            })
        });
    }
}

export function paymentSwitch() {
    const paymentSwitchButton = document.querySelectorAll<any>('.js_element_displayblock_preisuebersicht__payment_switch_button');
    const activeClass = 'element_displayblock_preisuebersicht__payment_switch_button--active';
    paymentSwitchButton.forEach(function (button) {
        button.addEventListener('keydown', function(event) { onKeydown(this, event, activeClass, 0) });
        button.addEventListener('click', function (){
            var activeButton = document.querySelector('.'+activeClass);
            var buttonOverlay = document.querySelector('.js_element_displayblock_preisuebersicht__payment_switch_button_overlay');
            var paymentType = button.dataset.payment_type;

            if (button.classList.contains(activeClass) == false) {
                buttonOverlay.classList.toggle('element_displayblock_preisuebersicht__payment_switch_button_overlay--toggle');
                setClassAndAttribute(activeButton, button, activeClass);
                changePrice(paymentType);
                pricingChangeAttributes();
            }
        });
    })
}

export function compareTableAccordion() {
    const triggerButtons = document.querySelectorAll<any>('.js_element_displayblock_preisuebersicht__compare_table_content_row');
    triggerButtons.forEach(function (button) {
        button.addEventListener('click', function (){
            const descriptionRow = this.nextElementSibling;
            const childButton = this.firstChild.firstChild;
            this.classList.toggle('element_displayblock_preisuebersicht__compare_table_content_row--open');
            if (childButton.getAttribute('aria-pressed') === 'true') {
                childButton.setAttribute('aria-pressed','false');
            }
            else {
                childButton.setAttribute('aria-pressed','true');
            }
            descriptionRow.classList.toggle('element_displayblock_preisuebersicht__compare_table_description_row--open');
        });
    });
}