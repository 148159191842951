interface EtDataInterface {
    // browserEventsFired: Map<string, boolean>,
    /**
     * html_head.tpl
     */
    sessionId?: string,
    presence: string,
    presenceId: number,
    lang: string,
    langcode: string,
    vwType: string,
    vwTypeId: number,
    vwName: string,
    vwId: number,
    environment: string,
    openMids?: number[],

    /**
     * Dynamic values
     */
    gte: any,
    personalJsonStatus?: string, // running|done|null/undefined
    viewportWidth: number,
    viewportHeight: number,
    scrollTop: number,
}

declare var et: {
    app: any,
    data: any|EtDataInterface,
    api: any,
};

if (!et.hasOwnProperty('app')) {
    et.app = {};
}

if (!et.hasOwnProperty('data')) {
    et.data = {};
}

if (!et.hasOwnProperty('api')) {
    et.api = {};
}

export var etExport = et;