interface sbOffcanvasOptionsInterface {
    elementName: string,
    className: string,
    callback1?: Function,
    callback1Options?: any,
    callback2?: Function,
    callback2Options?: any,
    scrollBarFake?: boolean
}

interface sbPersonalJsonDomCallbackArgs {
    htmlElement?: HTMLElement | null
    domKey: string,
    domValue: object | any,
    response: object | any
}

interface sbPersonalJsonDataCallbackArgs {
    dataKey: string,
    dataValue: object | any,
    response: object | any
}

export const customFunction = (et) => {
    et.app.camelize = (str: string) => {
        str = str.replace(/[\.\-_]/g, ' ');
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    };

    /**
     * === personal.json result ===
     * dom? {
     *   identifier: { // z.b. element_cart, element_garage__garage_content, login_dropdown
     *     html?: string,
     *     attributes?: {
     *       key1: value1,
     *       ...
     *     }
     *     callback?: string
     *   }
     * }
     *
     *
     * === result.dom integrieren ===
     * Auf den Container werden innerHTML=identifier.html und alle attributes werden per setAttribute übertragen.
     * Bsp: div#identifier oder div.js_pj[data-pj-item="identifier"] setzt html und attributes ein
     * Bsp: div.js_pj[data-pj-html] oder *.js_pj[data-pj-attributes] setzt html oder attributes ein
     *
     * === result.dom callback ===
     * Der Name des Callbacks kann in result.dom.identifier.callback stehen oder wird per
     * et.app.camelize('pj_' + (data|dom) + '_' + itendifier) geblidet.
     * In beiden Fällen MUSS die Funktion in et.app vorhanden sein.
     * Die Callback-Funktion wird für jedes gefundene Dom-Element (nach der Dom-Manipulation) aufgerufen
     * und einmal zusätzlich ohne elementbezug.
     *
     * Generisches Beispiel in Typescript für result.dom.element_cart_preview:
     * et.app.pjDataElementCartPreview = (dataValue:any, args:sbPersonalJsonDataCallbackArgs) => {}
     * et.app.pjDomElementCartPreview = (htmlElement:HTMLElement, args:sbPersonalJsonDomCallbackArgs) => {}
     * TODO umstellen auf document.addEventListener('et.pjXyz'...)
     *
     * Die Parameter:
     *   htmlElement ist ein HTMLElement wenn über die oben genannten Selektoren ein Element gefunden wurde
     *      und NULL im abschließenden Aufruf
     *   args.domKey = element_cart_preview
     *   args.domValue = das Objekt hinter result.dom.identifier
     *   args.response = die komplette response
     *   args.applied = die Anzahl der inserts/replacements wenn über die oben genannten Selektoren ein Element
     *      gefunden wurde und NULL im abschließenden Aufruf
     */
    et.app.callPersonalJson = (options?: any) => {
        // console.log('xhr personal.json', et.data.personalJsonRunning);

        let queryString = '';

        if (!options) {
            options = {queryString: {}};
        }

        if (typeof options === 'object' && options.hasOwnProperty('queryString')) {
            switch (typeof options.queryString) {
                case 'string':
                    if (options.queryString.indexOf('vw_type=') === -1) {
                        options.queryString += '&vw_type=' + et.data.vwType;
                    }
                    if (options.queryString.indexOf('vw_name') === -1) {
                        options.queryString += '&vw_name=' + et.data.vwName;
                    }
                    if (options.queryString.indexOf('vw_id') === -1) {
                        options.queryString += '&vw_id=' + et.data.vwId;
                    }
                    queryString = options.queryString;
                    break;
                case 'object':
                    if (!options.queryString.hasOwnProperty('vw_type')) {
                        options.queryString.vw_type = et.data.vwType;
                    }
                    if (!options.queryString.hasOwnProperty('vw_name')) {
                        options.queryString.vw_name = et.data.vwName;
                    }
                    if (!options.queryString.hasOwnProperty('vw_id')) {
                        options.queryString.vw_id = et.data.vwId;
                    }
                    queryString = Object.keys(options.queryString).map(
                        key => key + '=' + encodeURIComponent(options.queryString[key])
                    ).join('&');
                    break;
            }
        }

        if (typeof queryString === 'string') {
            if (queryString.length) {
                let pos = queryString.indexOf('?');
                if (pos === -1) {
                    queryString = '?' + queryString;
                } else if (pos > 0) {
                    console.error('queryString invalid #2', JSON.stringify({
                        type: typeof queryString,
                        queryString: queryString
                    }));
                }
            }
        } else {
            console.error('queryString invalid #1', JSON.stringify({
                type: typeof queryString,
                queryString: queryString
            }));
            return;
        }

        if (document.querySelectorAll('form.form').length) {
            queryString += '&form=1';
        }

        if (!et.data.personalJsonRunning) {
            et.data.personalJsonRunning = true;
            /**
             * @TODO / Feature: optimiertes caching
             * Ergebnis Prüfsumme in Result integrieren. in localStorage ablegen und Prüsfumme
             */

            /*document.dispatchEvent(new CustomEvent('et.before.callPersonalJson', {
                detail: null
            }));*/

            queryString = (queryString.length ? '?' + queryString.replace('?', '&') : '');
            fetch('/personal.json' + queryString, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors', // no-cors, cors, *same-origin
                cache: 'no-cache', // *default, no-cache, no-store, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                // headers: {
                //     'Content-Type': 'application/json',
                //     // 'Content-Type': 'application/x-www-form-urlencoded',
                // },
                // redirect: 'follow', // manual, *follow, error
                // referrer: 'no-referrer', // no-referrer, *client
                // body: JSON.stringify(data), // body data type must match "Content"
            })
                .then((response) => {
                    // console.log('callPersonalJson fetch response', response);
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then((response: any) => {
                    // console.log('callPersonalJson fetch data', response);
                    if (typeof response === 'object') {
                        let devLogs = [];
                        let callbackName: string;

                        // transfer Data from result to et.data
                        let propMap = [
                            // result.key to et.data.key
                            ['session_id', 'sessionId'],
                            ['sessionModel', 'sessionModel'],
                            ['sessionType', 'sessionType']
                        ];
                        propMap.forEach((mapItem) => {
                            if (typeof mapItem === 'object'
                                && mapItem.hasOwnProperty('length')
                                && mapItem.length === 2
                                && mapItem.hasOwnProperty(0)
                                && mapItem.hasOwnProperty(1)
                                && mapItem[0] in response
                            ) {
                                et.data[mapItem[1]] = response[mapItem[0]];
                            }
                        });

                        let dataPropName;
                        for (dataPropName in response) {
                            callbackName = et.app.camelize('pjData_' + dataPropName);
                            devLogs.push({callbackName: callbackName, type: 'data', found: callbackName in et.app});

                            let callbackData: sbPersonalJsonDataCallbackArgs = {
                                dataKey: dataPropName,
                                dataValue: response[dataPropName],
                                response: response
                            }

                            document.dispatchEvent(
                                new CustomEvent(
                                    'et.' + callbackName,
                                    {
                                        detail: callbackData
                                    }
                                )
                            );

                            if (callbackName in et.app && typeof et.app[callbackName] === 'function') {
                                et.app[callbackName].call(
                                    null,
                                    response[dataPropName],
                                    callbackData
                                );
                            }
                        }

                        if ('dom' in response) {
                            for (let domKey in response.dom) {
                                let domValue = response.dom[domKey] as any;
                                if ('callback' in domValue) {
                                    callbackName = domValue.callback;
                                } else {
                                    callbackName = et.app.camelize('pjDom_' + domKey);
                                }
                                devLogs.push({callbackName: callbackName, type: 'dom', found: callbackName in et.app});

                                ['#' + domKey, '.js_pj'].forEach((selector: string) => {
                                    document.querySelectorAll(selector).forEach((element: HTMLElement) => {
                                        let applied = 0;

                                        if ('html' in domValue
                                            && (element.getAttribute('id') === domKey
                                                || (element.dataset.hasOwnProperty('pjItem')
                                                    && element.dataset.pjItem === domKey)
                                                || (element.dataset.hasOwnProperty('pjHtml')
                                                    && element.dataset.pjHtml === domKey)
                                            )
                                        ) {
                                            element.innerHTML = domValue.html;
                                            applied++;
                                        }

                                        if ('attributes' in domValue
                                            && (element.getAttribute('id') === domKey
                                                || (element.dataset.hasOwnProperty('pjItem')
                                                    && element.dataset.pjItem === domKey)
                                                || (element.dataset.hasOwnProperty('pjAttributes')
                                                    && element.dataset.pjAttributes === domKey)
                                            )
                                        ) {
                                            for (let attrib in domValue.attributes) {
                                                if (attrib.length) {
                                                    element.setAttribute(attrib, domValue.attributes[attrib]);
                                                }
                                            }
                                            applied++;
                                        }

                                        let callbackData: sbPersonalJsonDomCallbackArgs = {
                                            htmlElement: element,
                                            domKey: domKey,
                                            domValue: domValue,
                                            response: response
                                        };

                                        if (applied) {
                                            document.dispatchEvent(new CustomEvent('et.' + callbackName, {
                                                detail: callbackData
                                            }));
                                        }
                                        if (applied && callbackName in et.app && typeof et.app[callbackName] === 'function') {
                                            et.app[callbackName].call(
                                                null,
                                                element,
                                                callbackData
                                            );
                                        }
                                    });
                                });

                                let callbackData: sbPersonalJsonDomCallbackArgs = {
                                    domKey: domKey,
                                    domValue: domValue,
                                    response: response
                                };

                                document.dispatchEvent(new CustomEvent('et.' + callbackName, {
                                    detail: callbackData
                                }));

                                if (callbackName in et.app && typeof et.app[callbackName] === 'function') {
                                    et.app[callbackName].call(
                                        null,
                                        null,
                                        callbackData
                                    );
                                }
                            }
                        }

                        if (document.documentElement.classList.contains('env_dev') && devLogs.length) {
                            console.groupCollapsed('DEV: callPersonalJson');
                            if ('table' in console) {
                                console.table(devLogs);
                            } else {
                                devLogs.forEach((devLog) => {
                                    console.log(devLog);
                                });
                            }
                            console.groupEnd();
                        }
                    }

                    let callbackData: sbPersonalJsonDomCallbackArgs = {
                        domKey: null,
                        domValue: null,
                        response: response
                    };

                    document.dispatchEvent(new CustomEvent('et.after.callPersonalJson', {
                        detail: callbackData
                    }));

                    if (typeof options === 'object' && options.hasOwnProperty('callback') && typeof options.callback === 'function') {
                        options.callback.call(null, response);
                    }

                    et.data.personalJsonStatus = 'done';
                    et.data.personalJsonRunning = false;
                })
                .catch((err) => {
                    console.error('fetch error: ', err);
                    et.data.personalJsonStatus = 'failed';
                    et.data.personalJsonRunning = false;

                    if (typeof options === 'object' && options.hasOwnProperty('callback') && typeof options.callback === 'function') {
                        options.callback.call(null);
                    }
                });
        }
    }

    /**
     * Helper function
     *
     * document.addEventListener('et.pjDataSessionModel', (event: CustomEvent) => {
     *     et.app.promisifyPersonalJsonEventData(event)
     *          .then((eventData: any) => {
     *              StorageHelper.setStorageData(eventData.response.sessionModel, eventData.response.sessionType, true);
     *          })
     *          .catch((e) => { console.error(e); });
     *      });
     *  });
     *
     * @param event
     * @return Promise
     */
    et.app.promisifyPersonalJsonEventData = (event: CustomEvent) => {
        return new Promise((resolve, reject) => {
            if (event
                && 'detail' in event
                && typeof event.detail === 'object'
            ) {
                resolve(event.detail);
            } else {
                reject(new Error('invalid personal json result data'));
            }
        });
    }

};
