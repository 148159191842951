interface CCData {
    gs: {};
    cs: {};
}

declare let CC: {
    d: CCData;
    _rc: (name: string) => any;
    _wc: (name: string, value: string, cookieLifetime: number) => void;
    _nv: (newCounterValue?: number, cookieLifetime?: number) => any;
    showNotice: () => boolean;
    jsn: (jsn: any) => void;
    g: (gkey: string) => any;
    c: (ckey: string) => any;
    cw: (ckey: string, code: string) => void;
    gw: (gkey: string, code: string) => void;
    pt: () => void;
};

export class etCookieControl {

    /**
     * data injected by function.cookiecontrol_js.php
     */
    public noticeElement: HTMLElement;
    public noticeVisibleClass: 'cookiecontrol_notice--visible';
    private focusableCookieElements: NodeList;
    private firstFocusableEl: object;
    private lastFocusableEl: object;
    private closeElement: HTMLElement;

    constructor() {
        document.querySelectorAll('.js_cookiecontrol_usersettings').forEach((element: HTMLElement) => {
            this.initUsersettingsForm(element);
        });

        this.noticeElement = document.querySelector('#cookiecontrol_notice,.cookiecontrol_notice');

        this.focusableCookieElements = this.noticeElement.querySelectorAll('input:not([type=hidden]), select, textarea, button, object, a, area[href], [tabindex]');
        this.firstFocusableEl = this.focusableCookieElements[0];
        this.lastFocusableEl = this.focusableCookieElements[this.focusableCookieElements.length - 1];
        this.closeElement = this.noticeElement.querySelector('.cookiecontrol_notice__close');
    }

    public init() {
        let popupTriggerElements = document.querySelectorAll<HTMLElement>('.js_cookiecontrol_popup_trigger');
        popupTriggerElements.forEach(function (element) {
            if (element && element.dataset.init !== 'done') {
                element.dataset.init = 'done';
                element.addEventListener('click', () => {
                    et.api.cookieControll.showPopup();
                });
            }
        });

        if (this.noticeElement) {
            let dataJSN = this.noticeElement.getAttribute('data-jsn') || '';

            if (CC) {
                CC.jsn(dataJSN);
                if (CC.showNotice()) {
                    et.api.cookieControll.showPopup();
                }
            }

            this.noticeElement.addEventListener('keydown', et.api.cookieControll.cookieControlKeyboardHandler);

            if (this.closeElement) {
                this.closeElement.addEventListener('click', () => {
                    et.api.cookieControll.hidePopup();
                });
            }

            this.noticeElement.querySelectorAll('button[data-cc-allow]').forEach((buttonElement: HTMLButtonElement) => {
                buttonElement.addEventListener('click', () => {
                    let doReload = true;
                    let allow = buttonElement.dataset.ccAllow || 'mandatory';
                    if (allow === 'all') {
                        this.noticeElement.querySelectorAll('input[type="radio"],input[type="checkbox"]').forEach((inputElement: HTMLInputElement) => {
                            inputElement.checked = true;
                        });
                    } else if (allow === 'mandatory') {
                        this.noticeElement.querySelectorAll('input[type="radio"],input[type="checkbox"]').forEach((inputElement: HTMLInputElement) => {
                            inputElement.checked = inputElement.disabled;
                        });
                    }

                    et.api.cookieControll.sendSettings(buttonElement.form, {close: true, reload: doReload});
                });
            });
        }
    }

    private cookieControlKeyboardHandler(e) {
        if (e.key == 'Tab') {
            //Rotate Focus
            if (e.shiftKey && document.activeElement === et.api.cookieControll.firstFocusableEl) {
                e.preventDefault();
                et.api.cookieControll.lastFocusableEl.focus();
            } else if (!e.shiftKey && document.activeElement === et.api.cookieControll.lastFocusableEl) {
                e.preventDefault();
                et.api.cookieControll.firstFocusableEl.focus();
            }
        }
        else if (e.key == 'Escape') {
            et.api.cookieControll.closeElement.click();
        }
    };

    public showPopup() {
        this.togglePopup(true);
        et.api.cookieControll.firstFocusableEl.focus();
    }

    public hidePopup() {
        this.togglePopup(false);
    }

    public togglePopup(show: boolean = null) {
        if (this.noticeElement) {
            let d = this.noticeElement.dataset;
            d.visible = show === true ? 'true' : (show === false ? 'false' : (d.visible === 'false' ? 'true' : 'false'));
        }
    }

    /**
     * what can be
     *  "close=1" which means "acceptDedault
     *
     * @param what string|HTMLFormElement
     * @param options
     */
    public sendSettings = (what: HTMLFormElement | string, options?: any) => {
        // console.log('sendSettings', what, options);

        let urlExt = '';
        let doReload = options && options.reload || false;
        let doClose = options && options.close || false;


        if (typeof what === 'string') {
            urlExt = what;
        } else if (what instanceof HTMLFormElement) {
            what.method = 'GET';
            const formdata:any = new FormData(what);
            const params = new URLSearchParams(formdata);
            urlExt = 'userSettings&' + params.toString();
        }

        if (urlExt !== '') {
            let xhr = new XMLHttpRequest();

            if (typeof options === 'object'
                && options.hasOwnProperty('onload')
                && typeof options.onload === 'function'
            ) {
                xhr.addEventListener('load', options.onload);
            } else {
                xhr.addEventListener('load', () => {
                    if (xhr.status > 400) {
                        console.error(xhr.response);
                    } else if (xhr.response.data) {
                        if (doClose) {
                            this.hidePopup();
                        }
                        if (doReload) {
                            window.location.reload();
                        }
                    } else {
                        console.error('response without data');
                    }
                });
            }

            xhr.addEventListener('error', () => {
                console.error(xhr.response);
            });

            xhr.responseType = 'json';
            xhr.open("GET", '/json.php?service=cookiecontrol&' + urlExt);
            xhr.send();
        }
    }


    public initUsersettingsForm(formElement: HTMLFormElement | HTMLElement) {
        if (formElement instanceof HTMLFormElement && formElement.dataset.initCc !== 'done') {
            formElement.dataset.initCc = 'done';

            formElement.addEventListener('submit', (event) => {
                event.preventDefault();
                this.sendSettings(formElement);
            });

            let cookieSettings = decodeURIComponent(CC._rc('CCUserSettings') || '').split('&');
            formElement.querySelectorAll('input[type="radio"],input[type="checkbox"]').forEach((inputElement: HTMLInputElement) => {
                if (typeof cookieSettings === "object" && cookieSettings.hasOwnProperty('length') && cookieSettings.length) {
                    cookieSettings.forEach((val: string) => {
                        let x = val.split('=');
                        if (inputElement.name === x[0] && inputElement.value === x[1]) {
                            inputElement.checked = true;
                        }
                    });
                }
                inputElement.addEventListener('change', () => {
                    this.sendSettings(inputElement.form);
                });
            });
        }
    }

}