function getNextSibling(elem, selector) {
    var sibling = elem.nextElementSibling;

    if (!selector) return sibling;

    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling
    }

}

function getPreviousSibling(elem, selector) {
    var sibling = elem.previousElementSibling;

    if (!selector) return sibling;

    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling
    }
}

export class Navigation {
    menu: HTMLElement;
    submenus: Element[];
    topLevelItems: Element[];
    itemVisibleClassName: string;
    bodyWidth: number;
    bodyHeight: number;
    scope: string;

    constructor(domElement) {
        this.menu = domElement;
        this.initMenu();
        this.submenus = [...this.menu.querySelectorAll('.menu__wrapper--submenu')];
        this.topLevelItems = [...this.menu.querySelectorAll('.menu__item--l1')];
    }

    // Größe des body onresize holen, um später nach Mobile oder Desktop zu schauen
    onresize(event) {
        this.bodyWidth = event.target.outerWidth;
        this.bodyHeight = event.target.outerHeight;
    }

    initMenu() {
        const menuIcon = document.getElementById('navigation_icon');
        let i:number;
        let menuitems;
        let menuitem;
        menuitems = document.getElementsByClassName('menu__item');
        for (i = 0; i < menuitems.length; i++) {
            menuitem = menuitems[i];
            menuitem.addEventListener('keydown', this.onKeydown.bind(this));
        }

        // Beim öffnen des Menüs das erste Element fokussieren
        menuIcon.addEventListener('click', this.onMenuIcon.bind(this));

        // Wenn außerhalb eines offenen Submenu geklickt wird, dann wird es geschlossen
        this.menu.addEventListener('focusout', this.onFocusout.bind(this));

        window.addEventListener("resize", this.onresize);
        this.bodyWidth = window.outerWidth;
        this.bodyHeight = window.outerHeight;
        if (this.bodyWidth >= 1025) {
            this.scope = 'desktop';
            this.itemVisibleClassName = 'menu__item--visible_desktop';
        }
        else {
            this.scope = 'mobile';
            this.itemVisibleClassName = 'menu__item--visible_mobile';
        }
    }

    onMenuIcon() {
        this.topLevelItems[0].firstChild.focus();
    }

    getSubmenu(menuitem) {
        return  menuitem.getElementsByClassName('menu__wrapper--submenu')[0];
    }

    hasSubmenu(menuitem) {
        const submenu = this.getSubmenu(menuitem);
        return submenu !== '' && submenu != 'undefined' && typeof submenu !== 'undefined';
    }

    isOpen(menuitem) {
        if (this.topLevelItems.includes(menuitem)) {
            const submenu = this.getSubmenu(menuitem);
            return this.elementHasClass(submenu, "menu__wrapper--active");
        }
        else {
            return true;
        }
    }

    elementHasClass(target, className) {
        return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
    }

    isAnySubmenuOpen() {
        for (var i = 0; i < this.submenus.length; i++) {
            if (this.elementHasClass(this.submenus[i], "menu__wrapper--active")) {
                return true;
            }
        }
        return false;
    }

    openSubmenu(menuitem) {
        const submenu = this.getSubmenu(menuitem);
        submenu.classList.add('menu__wrapper--active');
        const firstChild = submenu.getElementsByClassName(this.itemVisibleClassName)[0];
        this.setFocusToMenuitem(firstChild);
    }

    closeSubmenu(menuitem) {
        const parentElement = menuitem.parentElement;
        parentElement.classList.remove('menu__wrapper--active');
        const parentMenuitem = parentElement.parentElement;
        this.setFocusToMenuitem(parentMenuitem);
    }

    closeAllSubmenus() {
        let i:number;
        let activeSubmenus;
        let activeSubmenu;
        activeSubmenus = document.getElementsByClassName('menu__wrapper--active');
        for (i = 0; i < activeSubmenus.length; i++) {
            activeSubmenu = activeSubmenus[i];
            activeSubmenu.classList.remove('menu__wrapper--active');
        }

    }

    setFocusToMenuitem(newMenuitem) {
        let i:number;
        let menuitem;
        const menuitems = document.getElementsByClassName('menu__item');
        if (menuitems) {
            for (i = 0; i < menuitems.length; i++) {
                menuitem = menuitems[i];
                if (menuitem === newMenuitem) {
                    newMenuitem.firstChild.focus();
                }
            }
        }
    }

    setFocusToFirstMenuitem(currentMenuitem) {
        const parentElement = currentMenuitem.parentElement;
        const firstChild = parentElement.getElementsByClassName(this.itemVisibleClassName)[0];
        this.setFocusToMenuitem(firstChild);
    }

    setFocusToLastMenuitem(currentMenuitem) {
        const parentElement = currentMenuitem.parentElement;
        const lastChild = parentElement.lastChild;
        this.setFocusToMenuitem(lastChild);
    }

    setFocusToPreviousMenuitem(currentMenuitem) {
        let newMenuitem;
        const parentElement = currentMenuitem.parentElement;
        const lastChild = parentElement.lastChild;
        // const firstChild = parentElement.firstChild;
        const firstChild = parentElement.getElementsByClassName(this.itemVisibleClassName)[0];

        if (currentMenuitem === firstChild && !this.topLevelItems.includes(currentMenuitem)) {
            this.closeSubmenu(currentMenuitem);
        }
        else if (currentMenuitem === firstChild && this.topLevelItems.includes(currentMenuitem)) {
            newMenuitem = lastChild;
        }
        else {
            newMenuitem = getPreviousSibling(currentMenuitem, '.'+this.itemVisibleClassName);
        }
        this.setFocusToMenuitem(newMenuitem);
    }

    setFocusToNextMenuitem(currentMenuitem) {
        let newMenuitem;
        const parentElement = currentMenuitem.parentElement;
        const lastChild = parentElement.lastChild;
        // const firstChild = parentElement.firstChild;
        const firstChild = parentElement.getElementsByClassName(this.itemVisibleClassName)[0];

        if (currentMenuitem === lastChild) {
            newMenuitem = firstChild;
        }
        else {
            newMenuitem = getNextSibling(currentMenuitem, '.'+this.itemVisibleClassName);
        }
        this.setFocusToMenuitem(newMenuitem);
    }

    setFocusToNextSubmenu() {
        const submenus = document.querySelectorAll('.menu__wrapper--submenu');
        const activeSubmenu = document.getElementsByClassName('menu__wrapper--active')[0];
        let index = [].indexOf.call(submenus, activeSubmenu);

        if(this.isAnySubmenuOpen()) {
            this.closeAllSubmenus();
        }
        if (submenus.length === index + 1)
        {
            index++;
            this.setFocusToMenuitem(this.topLevelItems[index]);
        }
        else
        {
            index++;
            const parentElement = submenus[index].parentElement;
            this.openSubmenu(parentElement);
        }
    }

    setFocusToPreviousSubmenu() {
        const submenus = document.querySelectorAll('.menu__wrapper--submenu');
        const activeSubmenu = document.getElementsByClassName('menu__wrapper--active')[0];
        let index = [].indexOf.call(submenus, activeSubmenu);

        if(this.isAnySubmenuOpen()) {
            this.closeAllSubmenus();
        }
        if (0 === index)
        {
            this.setFocusToMenuitem(this.topLevelItems[this.topLevelItems.length - 1]);
        }
        else
        {
            index--;
            const parentElement = submenus[index].parentElement;
            this.openSubmenu(parentElement);
        }
    }

    onFocusout(event) {
        const menuContainsFocus = this.menu.contains(event.relatedTarget);
        if (!menuContainsFocus) {
            this.closeAllSubmenus();
        }
    }

    onKeydown(event) {
        event.stopPropagation();
        const tgt = event.currentTarget;
        const key = event.key;
        switch (key) {
            case ' ':
            case 'Enter':
                // Menü betreten oder Link klicken
                event.preventDefault();
                if (this.hasSubmenu(tgt)) {
                    this.openSubmenu(tgt);
                }
                else if(this.elementHasClass(tgt.firstChild, 'menu__link--back')) {
                    this.closeSubmenu(tgt);
                }
                else {
                    location.href = event.target.href;
                }
                break;
            case 'ArrowDown':
                // Zum nächsten Element und nach dem letzten wieder zum Ersten
                event.preventDefault();
                if (this.scope == 'desktop' && this.hasSubmenu(tgt) && this.topLevelItems.includes(tgt)) {
                    this.openSubmenu(tgt);
                }
                else {
                    this.setFocusToNextMenuitem(tgt);
                }
                break;
            case 'Esc':
            case 'Escape':
                // Menü verlassen
                event.preventDefault();
                this.closeSubmenu(tgt)
                break;
            case 'Left':
            case 'ArrowLeft':
                // Sollte auf der Hauptmenüebene nach links
                event.preventDefault();
                if (this.scope == 'desktop') {
                    this.setFocusToPreviousMenuitem(tgt);
                }
                else {
                    this.closeSubmenu(tgt);
                }
                break;
            case 'Right':
            case 'ArrowRight':
                // Sollte auf der Hauptmenüebene nach rechts
                event.preventDefault();
                if (this.hasSubmenu(tgt) && !this.topLevelItems.includes(tgt)) {
                    this.openSubmenu(tgt);
                }
                else {
                    this.setFocusToNextMenuitem(tgt);
                }
                break;
            case 'Up':
            case 'ArrowUp':
                // In dem Menü nach unten oder ganz oben das Menü verlassen
                event.preventDefault();
                this.setFocusToPreviousMenuitem(tgt);
                break;
            case 'Home':
            case 'PageUp':
                // Erstes Menü Element fokussieren
                event.preventDefault();
                this.setFocusToFirstMenuitem(tgt);
                break;
            case 'End':
            case 'PageDown':
                //  Letztes Menü Element fokussieren
                event.preventDefault();
                this.setFocusToLastMenuitem(tgt);
                break;
            case 'Tab':
                // Menü schließen und zum nächsten oder zum vorherigen
                if(event.shiftKey){
                    if (this.topLevelItems.includes(tgt.parentElement.parentElement) && this.isOpen(tgt)) {
                        event.preventDefault();
                        this.setFocusToPreviousSubmenu();
                    }
                }
                else {
                    if (this.topLevelItems.includes(tgt.parentElement.parentElement) && this.isOpen(tgt)) {
                        event.preventDefault();
                        this.setFocusToNextSubmenu();
                    }
                }
                break;
            default:
                break;
        }
    }
}